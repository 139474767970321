<template>
  <div
    class="product d-flex flex-column align-items-center justify-content-center"
    :class="selected ? 'selected' : ''"
  >
    <img
      v-if="product.technique.files && product.technique.files.length"
      :src="product.technique.files[0].url"
      width="90"
      height="80"
      class="product__image"
    />
    <img
      v-else
      src="https://placehold.it/90x80"
      width="90"
      height="80"
      class="product__image"
    />
    <div class="flex-1 product__data">
      <div
        class="product__title"
        v-html="$options.filters.highlight(product.technique.title, searchText)"
      ></div>
      <div class="product__price font-bold">{{ product.price | money }}</div>
    </div>
    <div class="prodcut__action-button">
      <button
        class="button"
        :class="selected ? 'selected' : ''"
        @click.stop="toggleSelected"
      >
        <span v-if="!selected">В корзину</span>
        <span v-else>Уже в корзине</span>
      </button>
    </div>
  </div>
</template>

<script>
import eventBus from "@/utils/event-bus";

export default {
  name: "ProductListItem",
  props: {
    product: Object,
    selected: Boolean
  },
  data() {
    return {
      searchText: ""
    };
  },
  methods: {
    toggleSelected() {
      this.$emit("toggle-selected", this.product.id);
    },
    doApplySearch(searchText) {
      this.searchText = searchText;
    }
  },
  created() {
    eventBus.$on("shop-search", this.doApplySearch);
  }
};
</script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1;
}

.product {
  width: 150px;
  min-height: 220px;
  margin: 10px;
  padding: 15px 15px 10px;
  flex-grow: 1;
  flex-basis: 20;
  background-color: #ffffff;
  border-radius: 7px;
  border: 1px solid #ffffff;
  cursor: pointer;
}
.product:hover,
.product.selected {
  border: 1px solid #f1f2f6;
}

.product__data {
  text-align: center;
}

.product__title {
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 10px;
  max-width: 120px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product__price {
  margin-bottom: 10px;
  font-weight: 600;
}

.button {
  font-size: 12px;
  background-color: #2c698c;
  border-radius: 4px;
  color: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  font-weight: 600;
  border: none;
  padding: 0.5em 1em;
  width: 120px;
}
.button:hover {
  background-color: rgba(0, 107, 144, 0.7);
}
.button.selected {
  background-color: #3bc915;
}
.button.selected {
  background-color: rgba(15, 203, 25, 0.7);
}

::v-deep .highlight {
  background-color: var(--highlight-color);
}
</style>
