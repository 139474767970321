<template>
  <TemplateBlock mod-class content-class="shop" title-page="Интернет-магазин">
    <div class="w-100 h-100 shop-page d-flex flex-column">
      <div>
        <ShopHeader />
      </div>
      <div class="scroll-container container flex-1">
        <div
          v-if="isContentLoading"
          class="w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <b-spinner></b-spinner>
        </div>
        <template v-else>
          <vuescroll>
            <div class="list d-flex" v-if="products && products.length">
              <div
                v-for="product in filteredProducts"
                :key="product.id"
                class="list__item mb-2"
                @click="goToProductPage(product.id)"
              >
                <ProductListItem
                  v-if="product.enabled"
                  :product="product"
                  :selected="selectedProductsIds.includes(product.id)"
                  @toggle-selected="doToggleSelected"
                />
              </div>
            </div>
          </vuescroll>
        </template>
      </div>
    </div>
  </TemplateBlock>
</template>

<script>
import RequestManager from "@/function/request/RequestManager";
import ProductListItem from "@/components/Shop/ProductListItem.vue";
import ShopHeader from "@/components/Shop/ShopHeader.vue";
import ShoppingCart from "@/utils/shopping-cart";
import ShopCartImg from "@/resource/img/shopping-cart-48.png";
import eventBus from "@/utils/event-bus";
import TemplateBlock from "@/components/TepmplateBlock.vue";
import vuescroll from "vuescroll";

export default {
  name: "Shop",
  components: {
    TemplateBlock,
    ProductListItem,
    ShopHeader,
    vuescroll
  },
  data() {
    return {
      isContentLoading: false,
      shopCartImg: ShopCartImg,
      products: [],
      selectedProductsIds: [],
      shoppingCart: undefined,
      selectedCategoryId: undefined,
      searchTextByEnter: ""
    };
  },
  computed: {
    filteredProducts() {
      let res = [];
      const enabledProducts = this.products.filter(product => product.enabled);
      if (this.selectedCategoryId) {
        res = enabledProducts.filter(
          product => product.technique.categoryId === this.selectedCategoryId
        );
      } else {
        res = enabledProducts;
      }
      res = res.filter(
        product =>
          product.technique &&
          product.technique.title &&
          product.technique.title
            .toLowerCase()
            .indexOf(this.searchTextByEnter.toLowerCase()) > -1
      );
      return res;
    }
  },
  methods: {
    goToProductPage(productId) {
      this.shoppingCart.saveProductsToOutStore();
      this.$router.push(`/shop/${productId}`);
    },
    rereadSelectedProductsIds() {
      this.selectedProductsIds = this.shoppingCart
        .getProducts()
        .map(scProduct => scProduct.product_id);
    },
    doToggleSelected(productId) {
      if (this.selectedProductsIds.indexOf(productId) > -1) {
        this.shoppingCart.removeProduct(productId);
      } else {
        this.shoppingCart.addProduct({
          product_id: productId,
          count: 1
        });
      }
      this.rereadSelectedProductsIds();
      eventBus.$emit("cart-change", this.shoppingCart.getProducts());
    },
    doApplyCategory(categoryId) {
      this.selectedCategoryId = categoryId;
    },
    doApplySearchByEnter(searchText) {
      this.searchTextByEnter = searchText;
    }
  },
  async created() {
    this.isContentLoading = true;
    let { items } = await RequestManager().productApi.listProduct({});
    items.forEach(product => (product.technique = {}));
    const techniqueIds = items.map(product => product.techniqueId);
    const techniqueItems = await RequestManager().techniqueApi.techniqueList({
      ids: techniqueIds
    });
    techniqueItems.items.forEach(tech => {
      const foundProduct = items.find(
        product => product.techniqueId === Number(tech.id)
      );
      if (foundProduct) {
        foundProduct.technique = tech;
      }
    });
    this.isContentLoading = false;
    this.products = items.splice(0);
    eventBus.$on("apply-category", this.doApplyCategory);
    eventBus.$on("shop-search-enter", this.doApplySearchByEnter);
    eventBus.$on("shop-search-filter", this.doApplySearchByEnter);
  },
  mounted() {
    this.shoppingCart = new ShoppingCart();
    this.rereadSelectedProductsIds();
  }
};
</script>

<style scoped lang="scss">
.full-width {
  width: 100% !important;
}
.pseudo-link {
  cursor: pointer;
}
.pseudo-link:hover {
  text-decoration: underline;
}
.font-bold {
  font-weight: 600;
}
.flex-1 {
  flex: 1;
}

.shop-page {
  background-color: #f9f9ff;
}

.list {
  flex-wrap: wrap;
}

.list__item {
  cursor: pointer;
}

.scroll-container {
  justify-content: flex-start !important;
}

.__vuescroll {
  height: calc(100vh - 210px) !important;
}
</style>
